var cube;
var radioGroup;
var nav;
var currentClass;
var hamburger;
var hamburgerMenu;
var youarehere;
var floorplan;
var lastView;

function setVars() {
	cube = document.querySelector(".cube");
	radioGroup = document.querySelector(".radio-group");
	nav = document.querySelector(".nav");
	currentClass = "";
	hamburger = document.querySelector(".hamburger");
	hamburgerMenu = document.querySelector(".hamburgermenu");
	youarehere = document.querySelectorAll(".youarehere");
	floorplan = document.querySelector(".floorplan");
}

function changeColor(showClass) {
	var content1 = document.querySelector(".content1");
	var content2 = document.querySelectorAll(".content2");
	var content3 = document.querySelector(".content3");
	var content4 = document.querySelectorAll(".content4");
	var content5 = document.querySelector(".content5");

	var fakeProjects = document.querySelector("#fakeProjects");
	var fakeAbout = document.querySelector("#fakeAbout");
	var realProjects = document.querySelector(".right");
	var realAbout = document.querySelector(".left");
	var fakeContent1 = document.querySelectorAll(".fakeContent1");
	var fakeContent2 = document.querySelectorAll(".fakeContent2");

	switch (showClass) {
		case "show-front":
			content1.style.opacity = "1";
			content2[0].style.opacity = "0";
			content2[1].style.opacity = "0";
			content2[2].style.opacity = "0";
			content3.style.opacity = "0";
			content4[0].style.opacity = "0";
			content4[1].style.opacity = "0";
			content4[2].style.opacity = "0";
			content5.style.opacity = "0";

			fakeContent1[0].style.opacity = "0";
			fakeContent1[1].style.opacity = "0";
			fakeContent1[2].style.opacity = "0";
			fakeProjects.style.opacity = "0";
			realProjects.style.opacity = "1";
			fakeContent2[0].style.opacity = "0";
			fakeContent2[1].style.opacity = "0";
			fakeContent2[2].style.opacity = "0";
			fakeAbout.style.opacity = "0";
			realAbout.style.opacity = "1";
			break;
		case "show-right":
			content1.style.opacity = "0";
			content2[0].style.opacity = "1";
			content2[1].style.opacity = "1";
			content2[2].style.opacity = "1";
			content3.style.opacity = "0";
			content4[0].style.opacity = "0";
			content4[1].style.opacity = "0";
			content4[2].style.opacity = "0";
			content5.style.opacity = "0";

			if (window.innerWidth < 801) {
				fakeProjects.style.opacity = "1";
				realProjects.style.opacity = "0";
				fakeContent1[0].style.opacity = "1";
				fakeContent1[1].style.opacity = "1";
				fakeContent1[2].style.opacity = "1";
			}
			fakeContent2[0].style.opacity = "0";
			fakeContent2[1].style.opacity = "0";
			fakeContent2[2].style.opacity = "0";
			fakeAbout.style.opacity = "0";
			realAbout.style.opacity = "1";
			break;
		case "show-back":
			content1.style.opacity = "0";
			content2[0].style.opacity = "0";
			content2[1].style.opacity = "0";
			content2[2].style.opacity = "0";
			content3.style.opacity = "1";
			content4[0].style.opacity = "0";
			content4[1].style.opacity = "0";
			content4[2].style.opacity = "0";
			content5.style.opacity = "0";

			fakeContent1[0].style.opacity = "0";
			fakeContent1[1].style.opacity = "0";
			fakeContent1[2].style.opacity = "0";
			fakeProjects.style.opacity = "0";
			realProjects.style.opacity = "1";
			fakeContent2[0].style.opacity = "0";
			fakeContent2[1].style.opacity = "0";
			fakeContent2[2].style.opacity = "0";
			fakeAbout.style.opacity = "0";
			realAbout.style.opacity = "1";
			break;
		case "show-left":
			content1.style.opacity = "0";
			content2[0].style.opacity = "0";
			content2[1].style.opacity = "0";
			content2[2].style.opacity = "0";
			content3.style.opacity = "0";
			content4[0].style.opacity = "1";
			content4[1].style.opacity = "1";
			content4[2].style.opacity = "1";
			content5.style.opacity = "0";

			if (window.innerWidth < 801) {
				fakeContent2[0].style.opacity = "1";
				fakeContent2[1].style.opacity = "1";
				fakeContent2[2].style.opacity = "1";
				realAbout.style.opacity = "0";
				fakeAbout.style.opacity = "1";
			}
			fakeContent1[0].style.opacity = "0";
			fakeContent1[1].style.opacity = "0";
			fakeContent1[2].style.opacity = "0";
			fakeProjects.style.opacity = "0";
			realProjects.style.opacity = "1";
			break;
		case "show-top":
			content1.style.opacity = "0";
			content2[0].style.opacity = "0";
			content2[1].style.opacity = "0";
			content2[2].style.opacity = "0";
			content3.style.opacity = "0";
			content4[0].style.opacity = "0";
			content4[1].style.opacity = "0";
			content4[2].style.opacity = "0";
			content5.style.opacity = "1";

			fakeContent1[0].style.opacity = "0";
			fakeContent1[1].style.opacity = "0";
			fakeContent1[2].style.opacity = "0";
			fakeProjects.style.opacity = "0";
			realProjects.style.opacity = "1";
			fakeContent2[0].style.opacity = "0";
			fakeContent2[1].style.opacity = "0";
			fakeContent2[2].style.opacity = "0";
			fakeAbout.style.opacity = "0";
			realAbout.style.opacity = "1";
			break;
	}

	if (showClass !== "show-right") {
		fakeProjects.style.display = "none";
	}
	if (showClass !== "show-left") {
		fakeAbout.style.display = "none";
	}
}

function underlineActive(clickedName) {
	var options = document.querySelectorAll("h3");

	options[0].style.textDecoration =
		clickedName === "right" ? "underline" : "none";
	options[1].style.textDecoration =
		clickedName === "right" ? "underline" : "none";
	options[2].style.textDecoration =
		clickedName === "back" ? "underline" : "none";
	options[3].style.textDecoration =
		clickedName === "back" ? "underline" : "none";
	options[4].style.textDecoration =
		clickedName === "left" ? "underline" : "none";
	options[5].style.textDecoration =
		clickedName === "left" ? "underline" : "none";
	options[6].style.textDecoration =
		clickedName === "top" ? "underline" : "none";
	options[7].style.textDecoration =
		clickedName === "top" ? "underline" : "none";

	youarehere[0].style.display = clickedName === "right" ? "inherit" : "none";
	youarehere[1].style.display = clickedName === "left" ? "inherit" : "none";
	youarehere[2].style.display = clickedName === "front" ? "inherit" : "none";
	youarehere[3].style.display = clickedName === "back" ? "inherit" : "none";
	youarehere[4].style.display = clickedName === "top" ? "inherit" : "none";
}

window.changeView = function changeView(clickedName) {
	var showClass = "show-" + clickedName;

	if (currentClass) {
		cube.classList.remove(currentClass);
	}
	cube.classList.add(showClass);
	if (showClass === "show-top") {
		lastView = currentClass;
	}
	currentClass = showClass;

	if (window.innerWidth < 800 && currentClass === "show-right") {
		document.querySelector("#fakeProjects").style.display = "block";
	} else {
		document.querySelector("#fakeProjects").scrollTo({ top: 0 });
		document.querySelector("#fakeProjects").style.opacity = "0";
		document.querySelector(".right").style.opacity = "1";
	}

	if (window.innerWidth < 800 && currentClass === "show-left") {
		document.querySelector("#fakeAbout").style.display = "block";
	} else {
		document.querySelector("#fakeAbout").scrollTo({ top: 0 });
		document.querySelector("#fakeAbout").style.opacity = "0";
		document.querySelector(".left").style.opacity = "1";
	}

	setTimeout(
		function () {
			changeColor(showClass);
		},
		window.innerWidth < 800 ? 1000 : 700
	);
	underlineActive(clickedName);
};

/// TRANSFORM

function transform() {
	var width = window.innerWidth;
	var height = window.innerHeight;

	if (window.innerWidth > 1500) {
		width = 1500;
	}

	var scene = document.querySelector(".scene");
	var box = document.querySelector(".cube");
	var face = document.querySelectorAll(".face");

	scene.style.width = window.innerWidth + "px";
	scene.style.height = height + "px";

	if (width < 800) {
		box.style.width = width + "px";
		box.style.height = height + "px";
	} else {
		box.style.width = width * 0.8 + "px";
		box.style.height = height * 0.8 + "px";
	}

	for (var i = 0; i < face.length; i++) {
		if (width < 800) {
			face[i].style.width = width + "px";
			face[i].style.height = height + "px";
		} else {
			face[i].style.width = width * 0.8 + "px";
			face[i].style.height = height * 0.8 + "px";
		}
	}
}

function transformHamburger() {
	// top 75px
	const height = floorplan.clientHeight;
	const marginY = (window.innerHeight - height + 70) / 2;
	floorplan.style.marginTop = marginY + "px";
}

/// LANGUAGE

window.setLanguage = function setLanguage(setDe) {
	var elementsDe = document.getElementsByClassName("de");
	var elementsEn = document.getElementsByClassName("en");

	for (var i = 0; i < elementsDe.length; i++) {
		elementsDe[i].style.display = setDe ? "block" : "none";
	}

	for (var i = 0; i < elementsEn.length; i++) {
		elementsEn[i].style.display = setDe ? "none" : "block";
	}

	var languages = document.getElementsByClassName("lang");

	languages[0].style.textDecoration = setDe ? "underline" : "none";
	languages[1].style.textDecoration = setDe ? "none" : "underline";

	if (lastView) {
		var last = lastView.split("-")[1];
		if (window.innerWidth < 800) {
			onHamburger(last);
		} else {
			changeView(last);
		}
	}
};

function checkUserLang() {
	var userLang = navigator.language || navigator.userLanguage;
	var de = userLang.includes("de");

	var name = "de";

	setLanguage(de, name);
}

/// iOS fixes

function setVh() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}

/// Hamburger

window.toggleHamburgerMenu = function toggleHamburgerMenu() {
	hamburger.classList.toggle("open");
	hamburgerMenu.classList.toggle("open");
	floorplan.classList.toggle("open");
	// hamburgerMenu.style.flex = e.classList.contains("open") ? "0" : "1";
};

window.onHamburger = function onHamburger(clickedName) {
	hamburger.classList.remove("open");
	hamburgerMenu.classList.remove("open");
	floorplan.classList.remove("open");

	setTimeout(() => {
		changeView(clickedName);
		underlineActive(clickedName);
	}, 350);
};

window.onload = function () {
	setVars();
	setVh();
	transform();
	transformHamburger();
	checkUserLang();
	changeView("front");
};

window.onresize = function onresize() {
	transform();
	transformHamburger();
	setVh();
};
